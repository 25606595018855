// s'applique pour l'impression
@media print {
  .environment {
    top: 7px;
  }

  .fiche {
    display: block;
  }

  button {
    display: none !important;
  }
  mat-checkbox {
    display: none;
  }
  label {
    color: black !important;
  }
  table {
    border: solid 1px gray;
    height: auto !important;
  }
  td {
    border: solid 1px gray;
  }
  .datatable-filter {
    display: none !important;
  }
  mat-form-field{
    color: black;
  }
  mat-label{
    color: black !important;
  }
  // Spécifique à Firefox
  input[type=number] {
    -moz-appearance: textfield;
  }
}
